import React, { useEffect } from 'react';
import styled from 'styled-components';
import {useGlobalWallet, connectWallet, checkConnectWallet, useNoWeb3} from '../context/AccountContext'
import { Redirect } from "react-router"

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 50px 100px;
  height: 81px;
  background: rgba(0,0,0,0.75);
  position: fixed;
  width: 100%;
  z-index: 10;

  @media only screen and (max-width: 414px) {
    padding: 5px 10px;
    margin-bottom: 30px;
  }
`;

const Logo = styled.div`
  width: 200px;
  height: 54px;
  margin-left: 250px;

  @media only screen and (max-width: 414px) {
    width: 100px;
    height: 40px;
    margin-left: 0px;
  }
`

const Button = styled.div`
  color: white;
  background-color: #3eb8df;
  width: 200px;
  height: 40px;
  border-radius: 20px;
  font-size: 20px;
  text-align: center;
  line-height: 40px;
  font-weight: 600;
  cursor: pointer;
  margin-right: 250px;

  &:hover {
    background-color: #a7a5a5;
  }

  @media only screen and (max-width: 414px) {
    font-size: 18px;
    width: 200px;
    height: 35px;
    line-height: 35px;
    margin-right: 20px;
  }
`;

const Address = styled.div`
  background-color: #3eb8df;
  color: white;
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  line-height: 40px;
  border-radius: 20px;
  padding: 0px 30px;
  margin-right: 250px;

  @media only screen and (max-width: 414px) {
    font-size: 14px;
    font-weight: 600;
    height: 30px;
    line-height: 30px;
    padding: 5px 20px;
    margin-right: 10px;
  }
`

const ListButton = styled.div`
  display:flex;
  margin-right: 10px;
`

const ethereum = window.ethereum

export default function Menu(props) {
  const [globalWallet, setGlobalWallet] = useGlobalWallet()
  const [noWeb3, setNoWeb3] = useNoWeb3()

  useEffect(() => {
    checkConnectWallet().then(result => {
      if (result) setGlobalWallet(result[0],result[1])
    })

    if (ethereum) {
      ethereum.on('accountsChanged', handleAccountsChanged)
      ethereum.on('disconnect', disconnected)
    }

    return () => {
      if (ethereum && ethereum.removeListener) {
        ethereum.removeListener('accountsChanged', handleAccountsChanged)
        ethereum.removeListener('disconnect', disconnected)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  function connectToWallet() {
    if (!window.ethereum) {
      setNoWeb3(true)
    }
    else {
      connectWallet().then(result => {
        if (result) setGlobalWallet(result[0],result[1])
      })
    }
  }

  function handleAccountsChanged(accountId) {
    setGlobalWallet(accountId[0],0)
  }

  function disconnected() {
    setGlobalWallet(null,null)
  }

  // function loginToNoiz() {
  //   signMessToLoginBurst(globalWallet.ethAddress).then(result => {
  //     setNoizAccount(result.data.account,result.data.accountRS,result.data.publicKey)
  //   }).catch(err => {
  //     console.log('user cancel')
  //   })
  // }

  function modifyAddress() {
    // return globalWallet.ethAddress.slice(0, 10) + '...' + globalWallet.ethAddress.slice(globalWallet.ethAddress.length - 6, globalWallet.ethAddress.length);
    return 'CONNECTED...' + globalWallet.ethAddress.slice(globalWallet.ethAddress.length - 6, globalWallet.ethAddress.length);
  }

  return (
    <Container>
      {
        noWeb3
          ? <Redirect to='/install-metamask' />
          : ''
      }
      <Logo>
        <img src='https://nftmarketplace.s3-ap-southeast-1.amazonaws.com/noizLogo.png' style={{height:'inherit'}} alt=""/>
      </Logo>

      {
        !globalWallet.ethAddress && !noWeb3
          ? (<Button onClick={() => connectToWallet()}>
              Connect MetaMask
            </Button>)
          : ( <ListButton>
                <Address style={!noWeb3 ? {marginLeft:'20px'} : {display:'none'}}>{globalWallet.ethAddress ? modifyAddress() : ''}</Address>
              </ListButton>
            )
      }
      
    </Container>
  );
}