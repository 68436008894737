import logo from '../assets/noiztokenlogo_2.png';
import footer from '../assets/footer.png';
import { useState } from 'react';
import '../styles/app.css'
import jsonp from 'jsonp'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const url = "https://acesoftware.us5.list-manage.com/subscribe/post-json?u=7eb9b110fafc3faabb58e7f2b&amp;id=0658bc027e";

const embeddedUrl = 'https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/20977384845782432989071332279148800799326882787034925228169324413104454369281?embed=true'
const queryString = require('query-string');
export default function TVBMarketplace() {
  const [email, setEmail] = useState("")
  const [sendEmailSuccess, setSendEmailSuccess] = useState(null)
  const sendEmail = ()=>{
    const formData = {
      EMAIL: email
    }
    jsonp(`${url}&${queryString.stringify(formData)}`, { param: 'c' }, (err, data) => {
      if(data.result =='success'){
        toast("Thank you. You've been added to our mailing list and will now be among the first to hear about new NFTs, big events and special offers.")
        setEmail("")
      }else{
        toast("You've already subscribed, thank you.")
      }
      // console.log("err: "+err);
      // console.log("data: "+JSON.stringify(data));
    });
  }
  return (
    <div className="App">
      <ToastContainer  position="top-center" autoClose={5000}/>
      <header className="App-header">
        <img src={'https://nftmarketplace.s3-ap-southeast-1.amazonaws.com/noizLogo.png'} className="App-logo" alt="logo" />
        <p>
        </p>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} className='formOnHeader'>
          <div className="subscript-form">
            <div action={url} method="POST" target="hiddenFrame">
              <input
                type="email"
                name="EMAIL"
                value={email}
                onChange={(e) => { setEmail(e.target.value) }}
                // onChange={(e) => { this.setState({ emailValue: e.target.value }); }}
                autoCapitalize="off"
                autoCorrect="off"
                placeholder="E-mail"
                className='input-menu'
              />
              <button onClick={()=>sendEmail()} type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="submit-button submit-button-menu" >Subscribe</button>
            </div>
          </div>
        </div>
      </header>
      <main className="App-main">
        <div className="App-hero">
          <div className="App-hero-image"></div>
          {/* <p>
            My Marketplace
          </p>
          <small>Digital collectibles from my company</small> */}
        </div>
        <iframe id="opensea-iframe" title="Embedded OpenSea Marketplace" src={embeddedUrl} width='100%' frameBorder='0' allowFullScreen></iframe>
      </main>
      <footer className="App-footer">
        <div className="footer-wrapper">
          <p>Subscribe to be informed about any updates</p>
          <div className="subscript-form">
            <div action={url} method="POST" target="hiddenFrame">
              <input
                type="email"
                name="EMAIL"
                value={email}
                onChange={(e) => { setEmail(e.target.value) }}
                // onChange={(e) => { this.setState({ emailValue: e.target.value }); }}
                autoCapitalize="off"
                autoCorrect="off"
                placeholder="E-mail"
              />
              <button onClick={()=>sendEmail()} type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="submit-button" >Subscribe</button>
            </div>
          </div>
          <ul className="social-media">
            <a href="https://www.facebook.com/NOIZchain" target="_blank">
              <li>
                <i className="fa fa-facebook" aria-hidden="true"></i>
              </li>
            </a>
            <a href="https://twitter.com/NOIZchain" target="_blank">
              <li><i className="fa fa-twitter" aria-hidden="true"></i></li>
            </a>
            <a href="https://www.linkedin.com/company/noizchain/" target="_blank">
              <li><i className="fa fa-linkedin" aria-hidden="true"></i></li>
            </a>
            <a href="https://www.instagram.com/noizchain/" target="_blank">
              <li><i className="fa fa-instagram" aria-hidden="true"></i></li>
            </a>
            <a href="https://www.youtube.com/channel/UC8SOjT2H9jCddqs3F9-VQ4g" target="_blank">
              <li><i className="fa fa-youtube" aria-hidden="true"></i></li>
            </a>
          </ul>
          <p>2021 © Copyrights NOIZchain Ltd.</p>
        </div>
        {/* Placeholder image for footer */}
        {/* <img style={{width: '100%'}} src={footer} className="footer" alt="footer" /> */}
      </footer>
    </div>
  );
}