import logo from '../assets/noiztokenlogo_2.png';
import footer from '../assets/footer.png';
import { useState } from 'react';
import '../styles/app.css'
import jsonp from 'jsonp'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom"
const url = "https://acesoftware.us5.list-manage.com/subscribe/post-json?u=7eb9b110fafc3faabb58e7f2b&amp;id=0658bc027e";

const embeddedUrl = 'https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/20977384845782432989071332279148800799326882787034925228169324413104454369281?embed=true'
const queryString = require('query-string');

export default function Disclaimer() {
  const history = useHistory()
  const [email, setEmail] = useState("")
  const [sendEmailSuccess, setSendEmailSuccess] = useState(null)
  const sendEmail = (e) => {
    e.preventDefault()
    const formData = {
      EMAIL: email
    }
    jsonp(`${url}&${queryString.stringify(formData)}`, { param: 'c' }, (err, data) => {
      if(data.result =='success'){
        toast("Thank you. You've been added to our mailing list and will now be among the first to hear about new NFTs, big events and special offers.")
        setEmail("")
      }else{
        toast("You've alreay subscribed, thank you.")
      }
      // console.log("err: "+err);
      // console.log("data: "+JSON.stringify(data));
    });
    
  }
  return (
    <div className="App">
      <ToastContainer  position="top-center" autoClose={5000}/>
      <header className="App-header">
        <img src={'https://nftmarketplace.s3-ap-southeast-1.amazonaws.com/noizLogo.png'} className="App-logo" alt="logo" onClick={() => history.push('/')}/>
        <p>
        </p>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} className='formOnHeader'>
          <div className="subscript-form">
            <form onSubmit={(e) => sendEmail(e)}>
              <div action={url} method="POST" target="hiddenFrame">
                <input
                  required
                  type="email"
                  name="EMAIL"
                  value={email}
                  onChange={(e) => { setEmail(e.target.value) }}
                  // onChange={(e) => { this.setState({ emailValue: e.target.value }); }}
                  autoCapitalize="off"
                  autoCorrect="off"
                  placeholder="E-mail"
                  className='input-menu'
                />
                <button onClick={()=>sendEmail()} type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="submit-button submit-button-menu" >Subscribe</button>
              </div>
            </form>
            
          </div>
        </div>
      </header>
      <main className="App-main">
        <div className="App-hero">
          <div className="App-hero-image"></div>
        </div>
        {/* <iframe src={'https://rarible.com/token/0xe386be399846782a355a2afb6e4c6bf44351cc25:28?tab=bids'}></iframe> */}
        <div className='disclaimerContainer'>
          <div className='disclaimerContent'>
            <div className='disclaimerTtile'>NFT Ownership Disclaimer</div>
            <div className='disclaimerDescription'>Terms of use for the purchase of《真話的清高》(Noble Truth) Non-Fungible Token (“NFT”) from The Voice Entertainment Group Limited (“Terms”)</div>
            <div className='disclaimerDescription'>PLEASE READ THESE TERMS CAREFULLY BEFORE PURCHASING OR BIDDING ON THIS NFT. THE VOICE ENTERTAINMENT GROUP LIMITED (“WE” OR “OUR”) IS ONLY WILLING TO MAKE THIS NFT AVAILABLE TO YOU IF YOU ACCEPT ALL OF THESE TERMS. BY PURCHASING OR BIDDING ON THIS NFT, YOU ARE CONFIRMING THAT YOU HAVE ATTAINED THE AGE OF 18 (OR THE AGE OF LEGAL MAJORITY AT YOUR JURISDICTION, IF HIGHER) AND THAT YOU UNDERSTAND AND AGREE TO BE BOUND BY ALL OF THESE TERMS. </div>
            <div className='disclaimerDescription'>This NFT contains 1-minute 32-second unpublished footage of the making of《真話的清高》(Noble Truth) MV that has been uniquely identified in the blockchain and uploaded as a limited edition Digital Work (“Digital Work”). The buyer (“you”) purchases this NFT to own a copy of the limited edition of the Digital Work. The purchase of this NFT is final and not cancelable or refundable. All our net proceeds for the first sale of this NFT go to Yan Oi Tong for charity purposes.</div>
            <div className='disclaimerDescription'>We (or, as applicable, our licensors) own and retain all legal right, title, and interest in and to the Digital Work, including all intellectual property rights therein. Upon your purchase of this NFT from a legitimate source and subject to your continued ownership of this NFT and full compliance with these Terms, we grant you a worldwide, non-exclusive, non-transferrable, royalty-free license to use, distribute and display the Digital Work solely for your own personal, non-commercial use, and for resale without alteration or reproduction. We reserve all rights in and to the Digital Work that are not expressly granted to you in these Terms.</div>
            <div className='disclaimerDescription'>You understand and agree that: (a) each subsequent sale of this NFT includes a payment of 15% of the final sale price of this NFT to the publishers as royalty fees in addition to the variable costs charged by NFT marketplaces; (b) the purchase and ownership of this NFT does not, except as otherwise set forth in these Terms, transfer any intellectual property rights or licenses in or to the Digital Work to you or any subsequent buyer; (c) you do not have the right, except as otherwise set forth in these Terms, to reproduce, distribute, or otherwise commercialize any elements of the Digital Work without our (or, as applicable, our licensors’) prior written consent in each case; and (d) you will not apply for, register, or otherwise use or attempt to use any trademarks, trade names or service marks of us or our licensors associated with or contained in the Digital Work, or any confusingly similar marks, anywhere in the world without our (or, as applicable, our licensors’) prior written consent in each case.</div>
            <div className='disclaimerDescription'>You agree that you may not, nor permit any person to do or attempt to do any of the foregoing without our (or, as applicable, our licensors’) prior written consent in each case: (a) modify the Digital Work in any way; (b) use the Digital Work, the name, image or likeness of any artiste in the Digital Work to advertise, market, or sell any products or services; (c) sell or distribute for commercial gain or otherwise commercialize merchandise that includes, contains, or consists of an elements of the Digital Work; (d) attempt to trademark, copyright, or otherwise acquire additional intellectual property rights in or to the Digital Work; or (e) otherwise utilize the Digital Work for your or any person’s commercial benefit. You agree to hold harmless and indemnify us and our parent, subsidiaries, affiliates, officers, agents, employees, advertisers, licensors, suppliers, or partners from and against any claim, liability, loss, damage (actual and consequential) of any kind or nature, suit, judgment, litigation cost and attorneys' fees arising out of or in any way related to your breach of these Terms.</div>
            <div className='disclaimerDescription'>The English version of these Terms prevails over any of its translation. The laws of Hong Kong govern these Terms and both we and you irrevocably submit to the non-exclusive jurisdiction of the Hong Kong courts.</div>
          </div>
        </div>
        
      </main>
      <footer className="App-footer">
        <div className="footer-wrapper">
          <p>Subscribe to be informed about any updates</p>
          <div className="subscript-form">
            <form onSubmit={(e) => sendEmail(e)}>
              <div action={url} method="POST" target="hiddenFrame">
                <input
                  required
                  type="email"
                  name="EMAIL"
                  value={email}
                  onChange={(e) => { setEmail(e.target.value) }}
                  autoCapitalize="off"
                  autoCorrect="off"
                  placeholder="E-mail"
                />
                <button onClick={()=>sendEmail()} type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="submit-button" >Subscribe</button>
              </div>
            </form>
          </div>
          <ul className="social-media">
            <a href="https://www.facebook.com/NOIZchain" target="_blank">
              <li>
                <i className="fa fa-facebook" aria-hidden="true"></i>
              </li>
            </a>
            <a href="https://twitter.com/NOIZchain" target="_blank">
              <li><i className="fa fa-twitter" aria-hidden="true"></i></li>
            </a>
            <a href="https://www.linkedin.com/company/noizchain/" target="_blank">
              <li><i className="fa fa-linkedin" aria-hidden="true"></i></li>
            </a>
            <a href="https://www.instagram.com/noizchain/" target="_blank">
              <li><i className="fa fa-instagram" aria-hidden="true"></i></li>
            </a>
            <a href="https://www.youtube.com/channel/UC8SOjT2H9jCddqs3F9-VQ4g" target="_blank">
              <li><i className="fa fa-youtube" aria-hidden="true"></i></li>
            </a>
          </ul>
          <p>2021 © Copyrights NOIZchain Ltd.</p>
        </div>
        {/* Placeholder image for footer */}
        {/* <img style={{width: '100%'}} src={footer} className="footer" alt="footer" /> */}
      </footer>
    </div>
  );
}