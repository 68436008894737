import Menu from '../components/Menu';
// import logo from '../assets/shout-gallery-logo.webp'
import mm_logo from '../assets/mm-logo.svg'
export default function InstallMetamask() {
  return (
    <div className="container">
      <Menu />
      {/* <div className='nftLogo galleryLogo'>
        <img src={logo} alt='' style={{width:'400px'}}/>
      </div> */}

      <div className='userInform'>
        <div className='userInformText'>You need a Ethereum Wallet to use this page</div>
        <div className='metamask'>
          <img src={mm_logo} alt='' />
          <div onClick={() => window.open('https://metamask.io/download.html','_blank')}>Install Metamask</div>
        </div>
        
      </div>
      <div className='bottomBar'>
          <img src='https://nftmarketplace.s3-ap-southeast-1.amazonaws.com/noizLogo.png' alt=''/>
          <div>2021 © Copyrights NOIZchain Ltd.</div>
        </div>
    </div>
  )
}