export const TypeOrder = {
  sell: 0,
  buy: 1
}

export const UnconfirmedTxType = {
  sell: 2,
  buy: 3
}

export const Api = {
  url: 'https://nft-market-place.poc-solutions.org',
}

export const NOIZ_DECIMAL = 100000000

export const ETH_DECIMAL = 1000000000000000000

export const BLOCK_TIME = new Date(2014,7,11,9,0).getTime()

export const ARTIST_INFO = {
  YanMingXi: {
    name: 'YAN MINGXI',
    summary: '',
    url: '',
    bio: '',
    description: ''
  }
}
