// import React, {useEffect} from 'react';
import './App.css';
import { Switch, Route, BrowserRouter } from 'react-router-dom';

import AccountContextProvider from './context/AccountContext'

import 'react-slideshow-image/dist/styles.css'
import InstallMetamask from './pages/InstallMetamask'
import TVBActress from './pages/TVBActress';
import TVBMarketplace from './pages/TVBMarketplace';
import Disclaimer from './pages/Disclaimer';
import Rarible from './pages/Rarible';

// function NoizChain() {
//   useEffect(() => {
//     window.open('https://noizchain.com/', "_self");
//   },[])
//   return(<></>)
// }

function App() {
  return (
    <AccountContextProvider>
      <BrowserRouter>
        <Switch>
          {/* <Route exact strict path="/" component={TVBMarketplace}  /> */}
          <Route exact strict path="/install-metamask" component={InstallMetamask}  />
          {/* <Route exact strict path="/tvb/yan-ming-xi" component={TVBActress}  /> */}
          <Route exact strict path="/nftownershipdisclaimer" component={Disclaimer}  />
          <Route exact strict path="/" component={Rarible}  />
        </Switch>
      </BrowserRouter>
    </AccountContextProvider>
  );
}

export default App;
