import React, {createContext,useContext,useCallback, useMemo, useReducer} from 'react';
import Web3 from 'web3';
import axios from 'axios'
import {Api, ETH_DECIMAL} from '../constants/config'

const CONNECT = 'CONNECT'
const LOGIN = 'LOGIN'
const NOWEB3 = 'NOWEB3'

const AccountContext = createContext()

function useAccountContext() {
  return useContext(AccountContext)
}

const initState = {
  ethAddress: null,
  balance: null,
  noizAccount: null,
  noizAccountRS: null,
  noizPublicKey: null,
  noWeb3: null
}

function reducer(state, {type, payload}) {
  switch (type) {
    case CONNECT: {
      const {ethAddress, balance} = payload
      return {
        ...state,
        ethAddress: ethAddress,
        balance: balance
      }
    }
    case LOGIN: {
      const {noizAccount, noizAccountRS, noizPublicKey} = payload
      return {
        ...state,
        noizAccount: noizAccount,
        noizAccountRS: noizAccountRS,
        noizPublicKey: noizPublicKey
      }
    }
    case NOWEB3: {
      const {noWeb3} = payload
      return {
        ...state,
        noWeb3: noWeb3
      }
    }
    default: {
      throw Error(`Unexpected action type in DataContext reducer: '${type}'.`)
    }
  }
}
export default function Provider({children}) {
  const [state, dispatch] = useReducer(reducer, initState)

  const updateWallet = useCallback((ethAddress, balance) => {
    dispatch({
      type: CONNECT,
      payload: {
        ethAddress,
        balance
      }
    })
  },[])

  const updateNoizAccount = useCallback((noizAccount, noizAccountRS, noizPublicKey) => {
    dispatch({
      type: LOGIN,
      payload: {
        noizAccount,
        noizAccountRS,
        noizPublicKey
      }
    })
  },[])

  const updateNoWeb3 = useCallback((noWeb3) => {
    dispatch({
      type: NOWEB3,
      payload: {
        noWeb3
      }
    })
  },[])

  // useEffect(() => {
  //   if (!window.ethereum) updateNoWeb3(true)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[])

  return (
    <AccountContext.Provider
      value={useMemo(() => [state, {updateWallet,updateNoizAccount,updateNoWeb3}],[state,updateWallet,updateNoizAccount,updateNoWeb3])}
    >
      {children}
    </AccountContext.Provider>
  )
}

export function useGlobalWallet() {
  const [state, {updateWallet}] = useAccountContext()
  const wallet = {
    ethAddress: state.ethAddress,
    balance: state.balance
  }
  return [wallet, updateWallet]
}

export function useNoizAccount() {
  const [state, {updateNoizAccount}] = useAccountContext()
  const account = {
    noizAccount: state.noizAccount,
    noizAccountRS: state.noizAccountRS,
    noizPublicKey: state.noizPublicKey
  }
  return [account, updateNoizAccount]
}

export function useNoWeb3() {
  const [state, {updateNoWeb3}] = useAccountContext()
  return [state.noWeb3, updateNoWeb3]
}

export function checkConnectWallet() {
  return new Promise((resolve, reject) => {
    const web3 = new Web3(Web3.givenProvider)
    if (web3.givenProvider) {
      web3.eth.getAccounts().then((accounts) => {
        if (accounts.length > 0){
          web3.eth.getBalance(accounts[0]).then(balance => {
            let newBalance = parseInt(balance) / ETH_DECIMAL
            resolve([accounts[0],newBalance])
          })  
        }
      })
    }
  })
}

export async function connectWallet() {
  return new Promise((resolve, reject) => {
    if (window.ethereum || window.web3){
      const web3 = new Web3(Web3.givenProvider)
      window.ethereum.enable().then(res => {
        web3.eth.getAccounts().then((accounts) => {
          web3.eth.getBalance(accounts[0]).then(balance => {
            let newBalance = parseInt(balance) / ETH_DECIMAL
            resolve([accounts[0],newBalance])
          })
        })       
      })
    }
  })
}

async function sendGenerateTokenRequest(signature) {
  try {
    const params = new URLSearchParams();
    params.append('requestType', 'getAccountId');
    params.append('secretPhrase', signature);

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    let response = await axios.post(Api.url + '/NOIZ', params, config);
    if (response) {
      return {
        success: true,
        data: response.data
      };
    }
  } catch (e) {
    return {
      success: false,
      message: 'Opps! Something went wrong. Please try later.',
    };
  }
}

export async function signMessToLoginBurst(ethAddress) {
  const msgParams = [
    {
      type: 'address',
      name: 'Address',
      value: ethAddress,
    },
  ];

  return new Promise((resolve, reject) => {
    const web3 = new Web3(Web3.givenProvider)
    web3.currentProvider.sendAsync(
      {
        method: 'eth_signTypedData',
        params: [msgParams, ethAddress],
        from: ethAddress,
      },
      function (err, result) {
        if (err) {
          resolve({
            success: false,
            message: err.message + '\nPlease sign in order to login!',
            denied: true,
          });
        }
        if (result.error) {
          resolve({
            success: false,
            message: result.error.message,
          });
        } else {
        }
        resolve(sendGenerateTokenRequest(result.result));
      }
    );
  });
}