import logo from '../assets/noiztokenlogo_2.png';
import footer from '../assets/footer.png';
import { useState } from 'react';
import '../styles/app.css'
import jsonp from 'jsonp'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom"
import tvbIdol from '../assets/tvb-idol.webp'

const url = "https://acesoftware.us5.list-manage.com/subscribe/post-json?u=7eb9b110fafc3faabb58e7f2b&amp;id=0658bc027e";

const embeddedUrl = 'https://rarible.com/token/0x60f80121c31a0d46b5279700f9df786054aa5ee5:1408848?tab=details'
const queryString = require('query-string');
export default function Rarible() {
  const history = useHistory()
  const [email, setEmail] = useState("")
  const [sendEmailSuccess, setSendEmailSuccess] = useState(null)
  const sendEmail = (e) => {
    e.preventDefault()
    const formData = {
      EMAIL: email
    }
    jsonp(`${url}&${queryString.stringify(formData)}`, { param: 'c' }, (err, data) => {
      if(data.result =='success'){
        toast("Thank you. You've been added to our mailing list and will now be among the first to hear about new NFTs, big events and special offers.")
        setEmail("")
      }else{
        toast("You've alreay subscribed, thank you.")
      }
      // console.log("err: "+err);
      // console.log("data: "+JSON.stringify(data));
    });
    
  }
  return (
    <div className="App">
      <ToastContainer  position="top-center" autoClose={5000}/>
      <header className="App-header">
        <img src={'https://nftmarketplace.s3-ap-southeast-1.amazonaws.com/noizLogo.png'} className="App-logo" alt="logo" onClick={() => history.push('/')}/>
        <p>
        </p>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} className='formOnHeader'>
          <div className="subscript-form">
            <form onSubmit={(e) => sendEmail(e)}>
              <div action={url} method="POST" target="hiddenFrame">
                <input
                  required
                  type="email"
                  name="EMAIL"
                  value={email}
                  onChange={(e) => { setEmail(e.target.value) }}
                  // onChange={(e) => { this.setState({ emailValue: e.target.value }); }}
                  autoCapitalize="off"
                  autoCorrect="off"
                  placeholder="E-mail"
                  className='input-menu'
                />
                <button type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="submit-button submit-button-menu" >Subscribe</button>
              </div>
            </form>
            
          </div>
        </div>
      </header>
      <main className="App-main bgBlack">
        <div className="App-hero">
          <div className="App-hero-image"></div>
        </div>
        {/* <iframe className='iframeCustom' src={embeddedUrl} width='100%' frameBorder='0' allowFullScreen></iframe> */}
        <div className='marketplaceContainer'>
          <div className='marketplaceContent'>
            <div className='marketplaceImg'>
              <div className='imgContainer'>
                <img src={tvbIdol} alt='' />
              </div>
              
            </div>
            <div className='marketplaceDetail'>
              <div className='tvbTitle'>炎明熹 - 真話的清高. 製作特輯 NFT</div>

              <div className='tvbDescriptionGroup'>
                <div className='tvbDescription'>炎明熹《真話的清高》(NobleTruth) MV製作特輯</div>
                <div className='tvbDescription'>首個NFT登場 受益將作慈善用途</div>
                <div className='tvbDescription'>《聲夢傳奇》三料冠軍炎明熹（Gigi）將首支個人單曲《真話的清高》MV製作特輯，化為非同質化代幣(NFT)。在影片中Gigi將剖白首次推出個人單曲及拍攝音樂影片的內心感受。</div>
                <div className='tvbDescription'>今次的NFT拍賣收益，扣除行政費，將全部捐出予《仁愛堂》作慈善用途，Gigi希望能夠將愛心傳遞給有需要的人，可以「施比受更有福。」</div>
                <div className='tvbDescription'>星夢娛樂首次跨界踏足加密數碼藝術市場，為Gigi推出個人首項NFT作品，見證她實踐夢想和正式出道。</div>
                <div className='tvbDescription'>年僅16歲的炎明熹參加《聲夢傳奇》成為人氣偶像，從學生搖身一變成為傳奇新星，首支個人單曲《真話的清高》，歌曲推出後迅即登上付費下載流行榜冠軍，而MV亦連續佔據Youtube熱門音樂三甲位置，卡拉Ok熱唱榜第一位。</div>
                <div className='tvbDescription'>她首支歌曲的MV製作特輯，化成非同質化代幣(NFT)，別具價值及收藏意義。</div>
                <div className='tvbDescription'>條款及細則 : </div>
                <a href='https://voiceentertainment.noizchain.com/nftownershipdisclaimer' className='tvbLink'>https://voiceentertainment.noizchain.com/nftownershipdisclaimer</a>
              </div>
              <div className='smallCross'></div>
              <div className='tvbDescriptionGroup'>
                <div className='tvbDescription'>提醒:</div>
                <div className='tvbDescription'>請注意當前的出價，並確保您有足夠的「以太幣」在參與競投。另外在出價之前，必須出價高於目前的價格。</div>
              </div>
              
              <div className='tvbBtnContainer'>
                <div className='tvbBtn' onClick={() => window.open('https://rarible.com/token/0x60f80121c31a0d46b5279700f9df786054aa5ee5:1408848?tab=details', '_blank')}>詳情</div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer className="App-footer">
        <div className="footer-wrapper">
          <p>Subscribe to be informed about any updates</p>
          <div className="subscript-form">
            <form onSubmit={(e) => sendEmail(e)}>
            <div action={url} method="POST" target="hiddenFrame">
              <input
                required
                type="email"
                name="EMAIL"
                value={email}
                onChange={(e) => { setEmail(e.target.value) }}
                autoCapitalize="off"
                autoCorrect="off"
                placeholder="E-mail"
              />
              <button type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="submit-button" >Subscribe</button>
            </div>
            </form>
            
          </div>
          <ul className="social-media">
            <a href="https://www.facebook.com/NOIZchain" target="_blank">
              <li>
                <i className="fa fa-facebook" aria-hidden="true"></i>
              </li>
            </a>
            <a href="https://twitter.com/NOIZchain" target="_blank">
              <li><i className="fa fa-twitter" aria-hidden="true"></i></li>
            </a>
            <a href="https://www.linkedin.com/company/noizchain/" target="_blank">
              <li><i className="fa fa-linkedin" aria-hidden="true"></i></li>
            </a>
            <a href="https://www.instagram.com/noizchain/" target="_blank">
              <li><i className="fa fa-instagram" aria-hidden="true"></i></li>
            </a>
            <a href="https://www.youtube.com/channel/UC8SOjT2H9jCddqs3F9-VQ4g" target="_blank">
              <li><i className="fa fa-youtube" aria-hidden="true"></i></li>
            </a>
          </ul>
          <p>2021 © Copyrights NOIZchain Ltd.</p>
        </div>
        {/* Placeholder image for footer */}
        {/* <img style={{width: '100%'}} src={footer} className="footer" alt="footer" /> */}
      </footer>
    </div>
  );
}